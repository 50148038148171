import config from "config/app.js";
import autoRedux from './autoRedux.js';
import moment from 'moment'
import React from 'react'
import Alert from 'components/Alert'
import { Tree, Layout } from "antd";
import { NavLink } from "react-router-dom";
const { Content } = Layout;
const { TreeNode } = Tree;

/**
* Socket IO client. The value should be set in redux/realtime.js
*/
window.raffleSocket = null;

/**
* Example: Commons.getSocket().emit('sample-event', {message: 'Hello Vietnam'})
*/
let getSocket = () => {
  return window.raffleSocket;
}

let isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const range = (start, end) => Array.from({ length: (end - start + 1) }, (v, k) => k + start);

const toObj = (array, key) => {
  var result = array.reduce(function (map, obj) {
    map[obj[key]] = obj;
    return map;
  }, {});

  return result;
}

const formatNumber = (num, demical = ',') => {
  return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + demical) || 0
}

const errorCodeMap = {
  not_logged_in: 'Bạn chưa đăng nhập',
  permission_denied: 'Bạn không có quyền thực hiện tính năng này',
  server_error: 'Có lỗi xảy ra. Vui lòng thử lại sau',
  invalid_company: 'invalid_company',
  invalid_salary: 'invalid_salary',
  collaborator_not_exist: 'Cộng tác viên không tồn tại',
  ticket_not_exist: 'Ticket không tồn tại',
  task_not_exist: 'Task không tồn tại',
  ticket_require_approval: 'Ticket cần được duyệt',
  ticket_closed: 'Ticket đã đóng',
  ticket_approved: 'Ticket đã được duyệt',
  wrong_reviewer: 'Sai thông tin Reviewer',
  invalid_ip: 'Vui lòng sử dụng IP văn phòng',
  invalid_receiver: 'Người nhận không đúng. Vui lòng kiểm tra lại',
  confidence_not_exist: 'Không tìm thấy kết quả. Vui lòng kiểm tra lại Mã hội thoại',
  already_requested: 'Bạn đã gửi yêu cầu trước đó rồi',
  already_registered: 'Bạn đã đăng ký nghỉ phép thành công rồi.'
}
const showError = (code) => {
  return errorCodeMap[code] || 'Hiện không thể thực hiện yêu cầu, vui lòng thử lại sau [' + code + ']'
}

const isHighRank = (rank) => {
  return ['Counsel', 'Assistant Manager', 'Manager', 'Senior Manager', 'Assistant Director', 'Director', 'Senior Director'].includes(rank)
}

const actionToText = (action) => {
  const actionMapping = {
    'create': 'created',
    'approve': 'approved',
    'reject': 'rejected',
    'cancel': 'canceled',
    'reopen': 'reopenned',
    'close': 'closed',
    'complete_and_close': 'completed and closed',
    'comment': 'commented',
    'upload': 'uploaded file',
    'remove_watchers': 'removed watcher',
    'add_watchers': 'added watcher',
    'remove_reviewers': 'removed reviewer',
    'add_reviewers': 'added reviewer',
    'assign_tasks': 'assigned task',
    'update_task': 'marked',
    'sign_contract': 'signed contract',
    'reject_contract': 'rejected contract',
    'notify_file': 'notified',
    'remove_file': 'deleted file',
    'edit_ticket': 'edited ticket',
  }
  return actionMapping[action] || action
}

const isNumeric = (str) => {
  if (typeof str == 'number') return true
  if (typeof str != 'string') return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


const projectManager = (team) => {
  return config.GAMES_PM_MAP[team] || null
}

const ifTicketApprovedBy = (ticket, email) => {
  return ticket.approved[email] === 1
}

const validateTaxCode = (taxCode) => {
  if (!taxCode) return false
  if (isNaN(taxCode)) return false
  if (taxCode.length < 10) return false
  if (!['0', '1', '2', '3', '4', '8'].includes(taxCode[0])) return false
  if (!/^\d+$/.test(taxCode)) return false
  return true
}

const removeVietnameseTones = (str) => {
  if (!str) return str
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
}

const getNearestPurchaseDay = (promiseDay) => {
  // get nearset Tues or Fri of given day
  // Sunday as 0 and Saturday as 6.
  const promiseWeekDay = promiseDay.day()
  if (moment().day() < promiseWeekDay) {
    if ([0, 1, 2, 6].includes(promiseWeekDay)) return moment().day(3).hour(17).minute(0)
    if ([3, 4, 5].includes(promiseWeekDay)) return moment().day(4).hour(17).minute(0)
  } else {
    if ([0, 1, 2, 6].includes(promiseWeekDay)) return moment().day(10).hour(17).minute(0)
    if ([3, 4, 5].includes(promiseWeekDay)) return moment().day(11).hour(17).minute(0)
  }
  return moment().day(11).hour(17).minute(0)
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
const eraseCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const renderTaxCodeWarning = () => {
  return (
    <div className="alert-message-page">
      <div role="alert" class="alert alert-danger">
        <div class="message">
          <p>LƯU Ý:</p>
          <p>Với đối tác chưa có Mã số thuế cá nhân, vui lòng thực hiện theo hướng dẫn như sau:</p>
          <ul>
            <li>Chi tiết cách tra cứu xem <a href="https://drive.google.com/file/d/1WGU_n9p0jtl6f7TnrNTmduhiz-MAGB_g/view" target="_blank">tại đây</a></li>
            <li>Bước 1: Download file bao gồm
              <a href="https://drive.google.com/file/d/1r8W7b59NGq5a86Q0GicPWm0IPPGjRIo9/view" target="_blank"> Giấy Ủy quyền đăng ký thuế thu nhập cá nhân và Tờ khai đăng ký thuế </a> (tạm gọi là Giấy ủy quyền)
              - Ghi rõ thông tin và ký đầy đủ kèm bản photo CMND/ CCCD.</li>
            <li>
              Bước 2: Nộp kèm các giấy tờ trên cho BỘ PHẬN KẾ TOÁN cùng Bộ hồ sơ thanh toán/tạm ứng phí dịch vụ cho đối tác
              cá nhân cho lần thanh toán/tạm ứng đầu tiên.
              <ul>
                <li>Các trường hợp không nộp đầy đủ giấy tờ, Bộ phận Kế toán từ chối xử lý ngay từ lúc xếp số.</li>
                <li>Các trường hợp đã nộp Giấy ủy quyền ở lần thanh toán/ tạm ứng đầu tiên không cần nộp kèm Giấy ủy quyền
                  cho những lần thanh toán/tạm ứng tiếp theo.</li>
              </ul>
            </li>

          </ul>
          <p>Thời gian áp dụng kể từ ngày 26/08/2021 cho đến khi có thông báo mới.</p>
        </div>
      </div>
    </div>
  )
}

const removeDuplicateFromList = (list) => {
  return [...new Set(list)]
}

const renderTreeNode = (data) => {
  return Object.entries(data).map(([key, value]) => {
    if (typeof value === "string" || typeof value === "number") {
      return <TreeNode title={`${key}: ${value}`} key={key} />;
    }
    if (Array.isArray(value)) {
      return (
        <TreeNode title={key} key={key}>
          {value.map((item, index) => (
            <TreeNode
              title={`[${index}]: ${JSON.stringify(item)}`}
              key={`${key}_${index}`}
            />
          ))}
        </TreeNode>
      );
    }
    if (typeof value === "object") {
      return (
        <TreeNode title={key} key={key}>
          {renderTreeNode(value)}
        </TreeNode>
      );
    }
    return null;
  });
};

const displayJson = (data) => {
  return (
    <Tree showLine defaultExpandAll>
      {renderTreeNode(data)}
    </Tree>
  );
};

function isJSONObject(obj) {
  try {
    JSON.stringify(obj);
    return true;
  } catch (error) {
    return false;
  }
}

const shortenEmail = (email) => {
  if (!email || !email.includes('@')) return email
  return email.split('@')[0]
}


export default {
  post: autoRedux.post,
  get: autoRedux.get,
  autoPost: autoRedux.autoPost,
  autoGet: autoRedux.autoGet,
  getSocket,
  isJsonString,
  range,
  toObj,
  showError,
  formatNumber,
  isHighRank,
  actionToText,
  projectManager,
  removeVietnameseTones,
  getNearestPurchaseDay,
  sleep,
  isNumeric,
  setCookie,
  getCookie,
  eraseCookie,
  ifTicketApprovedBy,
  validateTaxCode,
  renderTaxCodeWarning,
  removeDuplicateFromList,
  displayJson,
  isJSONObject,
  shortenEmail,
}
